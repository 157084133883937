import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import sanitizeHtml from 'sanitize-html'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Media } from 'gatsby-plugin-fresnel'
import Cart from '../components/Cart'
import DrawerMenu from '../components/DrawerMenu'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header'
import ProductCard from '../components/Product/ProductCard'
import SEO from '../components/seo'

const Collection = ({ data: { collection } }) => {
  const { title, description, descriptionHtml, handle, products, image } = collection

  const PageWrap = styled.div`
    ${tw`w-full`}
  `

  const CategoryInformation = styled.div(({ hasImage }) => [
    tw`px-8 md:px-20 lg:px-12 xl:px-20 pb-12 pt-24 lg:pt-32 md:py-40 lg:order-2 lg:fixed right-0 top-0 h-full z-30 bg-colekt-light-gray overflow-y-scroll lg:flex lg:align-middle text-colekt-dark-gray`,
    hasImage && tw`pt-12`,
    `
    max-width: 500px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }`,
  ])

  const CategoryContent = styled.div`
    ${tw``}
  `

  const CategoryImage = styled.figure`
    ${tw`h-auto`}
    background-color: #FCFCFC
  `

  const Products = styled.div`
    ${tw`flex flex-wrap`}
    background-color: #FCFCFC
  `

  const ProductCardWrap = styled.div`
    ${tw`w-full md:w-1/2 xxl:w-1/3`}
    background-color: #FCFCFC
  `

  const CategoryMeta = styled.div`
    ${tw``}
  `

  const CategoryTitle = styled.h1`
    ${tw`font-extended text-3xl tracking-wide`}
    ${tw`mb-4 md:mb-5`}
    line-height: 39px;
  `

  const CategoryDescription = styled.div`
    ${tw`font-mono text-lg`}
    letter-spacing: 0.5px;
  `

  return (
    <>
      <SEO title={title} description={description} path={handle} />
      <Header />
      <DrawerMenu />
      <Cart />
      <PageWrap className="whiteonblack aside__padding">
        {image ? (
          <Media lessThan="lg">
            <CategoryImage>
              <GatsbyImage
                objectFit="contain"
                loading="lazy"
                className="w-full"
                imgClassName="w-full"
                alt={`Colekt ${title}`}
                image={getImage(image?.localFile)}
              />
            </CategoryImage>
          </Media>
        ) : null}
        <CategoryInformation className="aside__width" hasImage={image}>
          <CategoryMeta>
            <CategoryTitle>{title}</CategoryTitle>
            <CategoryDescription
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(descriptionHtml),
              }}
            />
          </CategoryMeta>
        </CategoryInformation>
        <CategoryContent>
          <Products>
            {products?.map((product, key) => (
              <ProductCardWrap key={product.id}>
                <ProductCard category={title} list="Collection" position={key + 1} product={product} />
              </ProductCardWrap>
            ))}
          </Products>
          {image ? (
            <Media greaterThanOrEqual="lg">
              <CategoryImage>
                <GatsbyImage
                  className="w-full"
                  loading="lazy"
                  alt={`Colekt ${title}`}
                  image={getImage(image?.localFile)}
                />
              </CategoryImage>
            </Media>
          ) : null}
        </CategoryContent>
        <Footer />
      </PageWrap>
    </>
  )
}

Collection.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Collection

export const query = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    collection: shopifyCollection(id: { eq: $id }) {
      id
      title
      description
      descriptionHtml
      handle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 1400, placeholder: NONE)
          }
        }
      }
      products {
        id
        title
        slug: gatsbyPath(filePath: "/product/{ShopifyProduct.handle}")
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                layout: FULL_WIDTH
                placeholder: NONE
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        metafields {
          value
          key
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        metafields {
          value
          key
        }
        variants {
          sku
        }
        vendor
      }
    }
  }
`
